<template>
  <div>
    <!-- FILTERS -->
    <v-dialog
      v-model="showInfoData"
      transition="dialog-bottom-transition"
      width="1000px"
      height="700px"
      max-height="500px"
    >
      <template v-slot:activator="{}">
        <v-badge :dark="setDark" :color="'transparent'" overlap dense>
          <!-- CLEAR FILTERS -->
          <v-tooltip bottom v-if="check">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="elevation-2 vcad-btn"
                @click="deleteFilter()"
                outlined
                small
                color="secondary"
              >
                <v-icon> mdi-filter-remove </v-icon>
              </v-btn>
            </template>
            <span>Clear filters</span>
          </v-tooltip>
          <!-- :class="!check ? 'elevation-2 ml-1' : 'elevation-6 ml-1'" -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                class="mr-2 elevation-2 vcad-btn"
                small
                color="primary"
                v-on="on"
                @click="showInfoData = true"
              >
                <v-icon v-if="!check"> mdi-filter-plus-outline</v-icon>
                <v-icon v-else>mdi-filter-plus</v-icon>
              </v-btn>
            </template>
            <span>Add filters</span>
          </v-tooltip>
        </v-badge>
      </template>
      <v-card style="overflow-x: hidden">
        <v-toolbar dense flat dark color="primary">
          <v-toolbar-title dense>
            <v-icon>mdi-filter-plus</v-icon>
            <span class="pl-1">Filters</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              forceRerender();
              showInfoData = false;
            "
            ><v-icon>mdi-close</v-icon><span></span
          ></v-btn>
        </v-toolbar>
        <v-container class="pa-6">
          <v-row align="center" justify="center">
            <v-col class="text-center" dense cols="6">
              <!-- status filter -->
              <v-select
                height="180"
                v-model="statusFilter"
                :items="itemStatus"
                ref="select"
                chips
                outlined
                label="Status"
                dense
                item-text="txt"
                item-value="statusId"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-row class="align-center">
                    <v-col cols="10">
                      <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggleStatus"
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{ iconStatus }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon>
                        <v-icon @click="$refs.select.blur()">mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    label
                    outlined
                    v-bind="data.attrs"
                    close
                    class="ma-1 no-hover"
                    @click:close="removeChipStatus(data.item)"
                  >
                    <v-avatar left>
                      <v-icon :color="data.item.color">{{
                        data.item.ico
                      }}</v-icon>
                    </v-avatar>
                    {{ data.item.txt }}
                  </v-chip>
                </template>
                <!--<template v-slot:item="data">
                          <template>
                            <v-list-item-avatar>
                              <v-icon :color="data.item.color">{{
                                data.item.icon
                              }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.label"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>-->
              </v-select>
            </v-col>

            <!-- extension filter -->
            <v-col class="text-center" dense cols="6">
              <v-select
                v-model="extensionsFilter"
                :items="extensions"
                dense
                height="180"
                ref="select1"
                chips
                outlined
                label="Type"
                item-text="text"
                item-value="value"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-row class="align-center">
                    <v-col cols="10">
                      <v-list-item ripple @mousedown.prevent @click="toggleExt">
                        <v-list-item-action>
                          <v-icon>
                            {{ iconExtension }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon>
                        <v-icon @click="$refs.select1.blur()">mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="data">
                  <v-chip
                    width="150"
                    label
                    outlined
                    v-bind="data.attrs"
                    close
                    class="ma-1 no-hover"
                    @click:close="removeChipExtension(data.item)"
                  >
                    <v-avatar left>
                      <img :src="getExtensionIcon(data.item.value)" alt="" />
                    </v-avatar>
                    {{ data.item.value }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col class="text-center" dense cols="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                bottom
                origin="center center"
                transition="slide-y-transition"
                max-width="290px"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="margin: auto"
                    v-model="dateRangeText"
                    outlined
                    dense
                    label="Conversion date"
                    hint="From ~ To"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker dense v-model="date" range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      menu1 = false;
                      date = [];
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu1.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col dense class="text-center align-center" cols="6">
              <div>
                Range size :
                <strong>{{ sizeFilterString.join(" - ") }}</strong>
              </div>

              <v-range-slider
                thumb-label="always"
                dense
                outline
                v-model="sizeFilter"
                thumb-size="14"
                min="0"
                max="3e+9"
                style="margin: auto"
              >
                <template v-slot:thumb-label> </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col dense class="text-center align-center" cols="6">
              <v-select
                v-model="selectedConversionType"
                :items="filteredVcadFileListConfig"
                label="Conversion type"
                multiple
                outlined
                prepend-icon="mdi-format-list-bulleted-type"
                item-text="label"
                hint="Select one or more conversion types to apply."
                dense
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row align="center" dense justify="center">
            <v-col class="text-center" dense cols="12">
              <v-text-field
                v-model="idFileSearch"
                dense
                label="File ID"
                hint="Search files for file id (partial search)"
                persistent-hint
                prepend-icon="mdi-file-key"
              ></v-text-field>
            </v-col> -->
          <!--<v-col dense class="text-center" cols="6">
           <v-text-field
                  v-model="guidViewFile"
                  filled
                  dense
                  label="Guid View"
                  hint="Search files for Guid View"
                  persistent-hint
                  prepend-icon="mdi-file-key"
                ></v-text-field>
          </v-col>-->
          <!-- </v-row> -->
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="deleteFilter()"
            color="secondary"
            class="vcad-btn ml-4"
            outlined
            ><v-icon>mdi-filter-remove</v-icon
            ><span class="ml-1">Clear filters</span></v-btn
          >
          <v-btn
            small
            outlined
            @click="updateValue()"
            color="primary"
            class="vcad-btn ml-2"
            ><v-icon>mdi-filter</v-icon><span class="ml-1">Apply</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import prettyBytes from "pretty-bytes";
import { getFileIconExt } from "../utils/GetFileIconExtension";
import { vcadFileStatusDict } from "../utils/VCadItemStatusUtil";
export default {
  data() {
    return {
      selectedConversionType: [],
      sizeFilter: [0, 3e9],
      sizeFilterString: [0, this.getSize(3e9)],
      idFileSearch: null,
      showInfoData: false,
      menu1: false,
      date: [],
      statusFilter: [1, 2, 3, -1, 0, -2, -3],
      itemStatus: [
        // {
        //   id: -1,
        //   label: "Deleted",
        //   icon: "mdi-delete",
        //   color: "blue",
        // },
        // {
        //   id: 0,
        //   label: "To be processed",
        //   icon: "mdi-cloud-upload",
        //   color: "blue",
        // },
        // {
        //   id: 1,
        //   label: "Processing",
        //   icon: "mdi-rotate-right mdi-spin",
        //   color: "blue",
        // },
        // {
        //   id: 2,
        //   label: "Ready",
        //   icon: "mdi-checkbox-marked-circle",
        //   color: "green darken-4",
        // },
        // { id: 3, label: "Error", icon: "mdi-alert-octagon", color: "red" },
      ],
      extensionsFilter: [
        "zip",
        "ifczip",
        "ifc",
        "nwd",
        "obj",
        "stl",
        "dwg",
        "dgn",
        "nwc",
        "rvt",
        "iwm",
        "unknown types",
      ],
      selection: null,
      refresh: 0,
      tab: null,
      check: false,
      imageBase64: "",
      checkFileNotInlused: false,
      extensionsPermitted: [
        "zip",
        "ifczip",
        "ifc",
        "nwd",
        "obj",
        "stl",
        "dwg",
        "nwc",
        "rvt",
        "iwm",
      ],
      extensions: [
        { value: "zip", text: "zip" },
        { value: "ifczip", text: "ifczip" },
        { value: "ifc", text: "ifc" },
        { value: "nwd", text: "nwd" },
        { value: "obj", text: "obj" },
        { value: "stl", text: "stl" },
        { value: "dwg", text: "dwg" },
        { value: "dgn", text: "dgn" },
        { value: "nwc", text: "nwc" },
        { value: "rvt", text: "rvt" },
        { value: "iwm", text: "iwm" },
        { value: "unknown types", text: "unknown types" },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["supportedVcadFileTypes", "vcadFileListConfig"]),
    filteredVcadFileListConfig() {
      // REMOVING NULL VALUES FROM CONFIGS
      return this.vcadFileListConfig.filter((config) => config.label !== null);
    },
    fileConfigLabelMap() {
      let slotLabel = {};
      this.items.forEach((item) => {
        item.groupedList.forEach((slot) => {
          if (slot.type === 0) {
            slotLabel[slot.type] = "OPEN";
          } else {
            let filteredConfigs = this.vcadFileListConfig.find(
              (config) => config.type == slot.type
            );
            if (filteredConfigs) {
              slotLabel[slot.type] = filteredConfigs.label;
            }
          }
        });
      });
      console.log(this.items);
      return slotLabel;
    },
    setDark() {
      if (localStorage.setPrimaryColorAppBar) {
        if (!(localStorage.setPrimaryColorAppBar.toLowerCase() === "true")) {
          //if the primary color is not setted
          if (this.$vuetify.theme.dark) {
            //otherwise set the 'dark' property of app bar respect to the dark/light mode
            return true;
          } else {
            return false;
          }
        } else {
          return false; //set always 'dark' property of app bar to false
        }
      } else {
        if (this.$vuetify.theme.dark) {
          //if the primary color is  setted
          //otherwise set the 'dark' property of app bar respect to the dark/light mode
          return true;
        } else {
          return false;
        }
      }
    },
    dateRangeText() {
      if (this.date) {
        return this.date.join(" ~ ");
      } else {
        return "";
      }
    },
    allExtensions() {
      return this.extensionsFilter.length === this.extensions.length;
    },
    likesSomeExtension() {
      return this.extensionsFilter.length > 0;
    },
    iconExtension() {
      if (this.allExtensions) return "mdi-close-box";
      if (this.likesSomeExtension) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allStatus() {
      return this.statusFilter.length === this.itemStatus.length;
    },
    likesSomeStatus() {
      return this.statusFilter.length > 0 && !this.allStatus;
    },
    iconStatus() {
      if (this.allStatus) return "mdi-close-box";
      if (this.likesSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.extensionsFilter = [
        "zip",
        "ifczip",
        "ifc",
        "nwd",
        "obj",
        "stl",
        "dwg",
        "dgn",
        "nwc",
        "rvt",
        "iwm",
        "unknown types",
      ];
      this.statusFilter = [1, 2, 3, -1, 0];
      this.sizeFilter = [0, 3e9];
      this.date = [];
      this.idFileSearch = null;
    },
    removeChipStatus(item) {
      let index = this.statusFilter.indexOf(item.statusId);
      this.statusFilter.splice(index, 1);
      return this.statusFilter;
    },
    removeChipExtension(item) {
      let index = this.extensionsFilter.indexOf(item.value);
      this.extensionsFilter.splice(index, 1);
      return this.extensionsFilter;
    },
    getExtensionIcon(ext) {
      return getFileIconExt(ext);
    },
    toggleExt() {
      this.$nextTick(() => {
        if (this.likesSomeExtension) {
          this.extensionsFilter = [];
        } else {
          this.extensionsFilter = this.extensions.slice();
        }
      });
    },
    getSize(val) {
      const ret = prettyBytes(val);
      if (ret == "0 B") {
        return "0";
      }

      return ret;
    },
    toggleStatus() {
      this.$nextTick(() => {
        if (this.allStatus) {
          this.statusFilter = [];
        } else {
          this.statusFilter = this.itemStatus.slice();
        }
      });
    },
    remove(item) {
      const index = this.itemStatus.indexOf(item.id);
      if (index >= 0) {
        this.itemStatus.splice(index, 1);
      }
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString();
    },
    deleteFilter() {
      this.check = false;
      this.$emit("filterApply", false);
      this.forceRerender();
      this.showInfoData = false;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    clear() {
      this.selection = null;
    },
    updateValue() {
      const getValidChildren = (slotGroup) => {
        const checkConversion = this.selectedConversionType;
        const statusFilter = this.statusFilter;
        let checkId = this.idFileSearch;
        let validChildren = slotGroup.groupedList.filter((curSlot) => {
          const fileSize = JSON.parse(curSlot.info).b360Item.storageSize;
          const hasValidId = checkId ? curSlot.id.includes(checkId) : true;
          const statusCondition = statusFilter.includes(curSlot.status);
          const dateFromCondition = this.date[0]
            ? new Date(curSlot.dateStartConv).setHours(0, 0, 0, 0) >=
              new Date(this.date[0]).setHours(0, 0, 0, 0)
            : true;
          const dateToCondition = this.date[1]
            ? new Date(curSlot.dateStartConv).setHours(0, 0, 0, 0) <=
              new Date(this.date[1]).setHours(0, 0, 0, 0)
            : true;
          const fileExtention = curSlot.name.split(".").pop().toLowerCase();
          const extensionsCondition = !this.extensionsFilter.includes(
            "unknown types"
          )
            ? this.extensionsFilter.includes(fileExtention)
            : this.extensionsFilter.includes(fileExtention) ||
              !this.extensionsPermitted.includes(fileExtention);
          const sizeCondition =
            fileSize >= this.sizeFilter[0] && fileSize <= this.sizeFilter[1];
          const conversionTypeCondition =
            checkConversion.length === 0
              ? true
              : checkConversion.includes(this.fileConfigLabelMap[curSlot.type]);

          return (
            hasValidId &&
            statusCondition &&
            dateFromCondition &&
            dateToCondition &&
            extensionsCondition &&
            sizeCondition &&
            conversionTypeCondition
          );
        });

        return validChildren;
      };
      let validItems = [];
      this.items.forEach((ele) => {
        const tempEle = Object.assign({}, ele);
        const validChildren = getValidChildren(tempEle);
        tempEle.groupedList = validChildren;
        if (validChildren.length > 0) {
          validItems.push(tempEle);
        }
      });
      this.check = true;
      this.$emit("input", validItems);
      this.$emit("filterApply", true);
      this.showInfoData = false;
    },
  },
  mounted() {
    this.itemStatus = Object.values(vcadFileStatusDict);
  },
  created() {
    const filteredConfigs = this.vcadFileListConfig.filter(
      (config) => config.label !== null
    );

    this.vcadFileListConfig = filteredConfigs;
  },
  watch: {
    sizeFilter(to) {
      this.sizeFilterString = [this.getSize(to[0]), this.getSize(to[1])];
    },
  },
};
</script>

<style scoped>
.no-hover:hover::before {
  background-color: transparent !important;
}
</style>